import React, { Component } from "react";
import HeaderTwo from "../components/common/HeaderTwo";
// import Breadcrumb from "../components/common/Breadcrumb";
import LoginBox from "../components/other/account/LoginBox";
import NewsLetter from "../components/other/cta/NewsLetter";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
// import login_img from "../assets/images/login-page-image.png";

import MetaDecorator from "../utils/metaDecorator";
import metaData from "../meta/login";
class Login extends Component {
  state = {
    breadcrumbimg: require("../assets/images/s4.jpg"),
  };
  render() {
    return (
      <main className="login-page">
        {/* SEO-Meta-Data */}
        {metaData.show && (
          <MetaDecorator
            title={metaData.pageTitle}
            description={metaData.pageDescription}
          />
        )}
        {/* Header */}
        <HeaderTwo history={this.props.history} />

        {/* Breadcrumb */}
        {/* <Breadcrumb CurrentPgTitle="Login" img={this.state.breadcrumbimg} /> */}

        <section
          style={{
            marginTop: 140,
          }}
          className="form-shared padding-top-40px padding-bottom-100px"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <LoginBox
                  title="Log in to Service64"
                  history={this.props.history}
                  subtitle="By logging in you can manage your profile or report unwanted review"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Newsletter */}
        <NewsLetter />

        {/* Footer */}
        <Footer />

        <ScrollTopBtn />
      </main>
    );
  }
}

export default Login;
