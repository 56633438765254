import React, { Component } from "react";
import BannerOneHeroHeading from "./BannerOneHeroHeading";
import BannerOneSearchInput from "./BannerOneSearchInput";
// import BannerOneCategories from "./BannerOneCategories";

export default class BannerOne extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <section className="hero-wrapper">
          {/* <div className="hero-overlay"></div> */}
          <div className="container">



            <div className="row">
              <div className="col-lg-2"></div>

              <div className="col-lg-8">
                {/* Banner One Hero Heading */}
                <BannerOneHeroHeading />
              </div>

              <div className="col-lg-2"></div>
            </div>

            <div className="row">
              <div className="col-lg-1"></div>

              <div className="col-lg-10">

                {/* Banner One Search Input */}
                <BannerOneSearchInput history={this.props.history} />

                {/* Banner One Categories */}
                {/* <BannerOneCategories /> */}
              </div>

              <div className="col-lg-1"></div>
            </div>




            
          </div>
        </section>
      </>
    );
  }
}
