import React, { Component } from "react";
import Skeleton from 'react-loading-skeleton';


class SkeletonCard extends Component {
    render() {
      return (
        <div className="card-item-skeleton" style={{padding:30, width:"30%"}}>
          <h1 style={{marginBottom:20,}}>{this.props.title || <Skeleton width={100} height={100} circle={true}/>}</h1>
          <h1>{this.props.title || <Skeleton width={200} />}</h1>
          {this.props.body || <Skeleton count={1} />}
          {this.props.body || <Skeleton count={1} width={250} />}
        </div>
      );
    }
  }

  export default SkeletonCard;