import React from 'react';

const BannerOneHeroHeading = () => {
    const title = 'Find the best service providers in your area with us';

    return (
        <div className="hero-heading">
            <div className="section-heading">
                <h1 className="sec__title cd-headline zoom">
                    {title}
                </h1>
            </div>
        </div>
    );
};

export default BannerOneHeroHeading;
