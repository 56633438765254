import React, { Component } from "react";
// import SignUpBox from "../components/other/account/SignUpBox";
import HeaderTwo from "../components/common/HeaderTwo";
// import Breadcrumb from "../components/common/Breadcrumb";
// import NewsLetter from "../components/other/cta/NewsLetter";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
// import { Link } from "react-router-dom";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Seller_reg_form from "./listings/AddListing.js";
import MetaDecorator from "../utils/metaDecorator";
import metaData from "../meta/signUp";
class SignUp extends Component {
  state = {
    breadcrumbimg: require("../assets/images/s4.jpg"),
  };
  render() {
    return (
      <main className="signup-page">
        {/* SEO-Meta-Data */}
        {metaData.show && (
          <MetaDecorator
            title={metaData.pageTitle}
            description={metaData.pageDescription}
          />
        )}

        {/* Header */}
        <HeaderTwo history={this.props.history} />

        {/* Breadcrumb */}
        {/* <Breadcrumb
          CurrentPgTitle="Become a Service Provider"
          img={this.state.breadcrumbimg}
        /> */}

        <section
          style={{
            marginTop: 140,
          }}
          className="form-shared padding-top-40px"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 mx-auto signupHeading">
                <h2>Sign up</h2>
                <p>
                  {" "}
                  Signup here to get calls from your valuable customers, the
                  information you provide here will be used to demonstrate your
                  expertise.{" "}
                </p>
              </div>
            </div>
          </div>
        </section>

        <Seller_reg_form history={this.props.history} />

        {/* Newsletter 
                <NewsLetter />

                {/* Footer */}
        <Footer />

        <ScrollTopBtn />
      </main>
    );
  }
}

export default SignUp;
