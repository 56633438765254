import React, { Component } from "react";
import HeaderTwo from "../../components/common/HeaderTwo";
// import Breadcrumb from "../../components/common/Breadcrumb";
import PlaceGrid from "../../components/places/PlaceGrid";
// import Button from "../../components/common/Button";
// import { FiRefreshCw } from "react-icons/fi";
import NewsLetter from "../../components/other/cta/NewsLetter";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
// import GenericHeader from "../../components/common/GenericHeader";

import MetaDecorator from "./../../utils/metaDecorator";
import metaData from "./../../meta/listingGrid";

import { connect } from "react-redux";
// import { bindActionCreators } from 'redux';
// import { search_res } from '../../store/action';

class ServiceProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bdimg: require("../../assets/images/s4.jpg"),
    };
  }
  render() {
    return (
      <main className="listing-grid">
        {/* SEO-Meta-Data */}
        {metaData.show && (
          <MetaDecorator
            title={metaData.pageTitle}
            description={metaData.pageDescription}
          />
        )}

        {/* Header */}
        <HeaderTwo history={this.props.history} />

        {/* Breadcrumb */}
        {/* <Breadcrumb
          CurrentPgTitle="Listing"
          MenuPgTitle="Listings"
          img={this.state.bdimg}
        /> */}

        {/* Place Grid */}
        <section
          style={{
            marginTop: 140,
          }}
          className="card-area padding-top-40px padding-bottom-100px"
        >
          <div className="container">
            {/* <div className="row">
                    <div className="col-lg-12">
                        <GenericHeader />
                    </div>
                </div> */}

            <div className="row">
              <PlaceGrid history={this.props.history} />
            </div>
          </div>
        </section>

        {/* Newsletter */}
        <NewsLetter />

        {/* Footer */}
        <Footer />

        <ScrollTopBtn />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    item: state,
  };
};

export default connect(mapStateToProps, null)(ServiceProvider);
