import React, { Component } from "react";
import HeaderTwo from "../components/common/HeaderTwo";
import SectionsHeading from "../components/common/SectionsHeading";
import NewsLetter from "../components/other/cta/NewsLetter";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import MetaDecorator from "../utils/metaDecorator";
import metaData from "../meta/privacyPolicy";

class PrivacyPolicy extends Component {
  render() {
    return (
      <main className="about-page">
        {/* SEO-Meta-Data */}
        {metaData.show && (
          <MetaDecorator
            title={metaData.pageTitle}
            description={metaData.pageDescription}
          />
        )}

        <HeaderTwo history={this.props.history} />

        {/* Team Area */}
        <section
          style={{
            marginTop: 180,
          }}
          className="team-area padding-bottom-70px"
        >
          <div className="container">
            <div className="row section-title-width text-center">
              <SectionsHeading
                title="Privacy Policy"
                desc="service64.com, we respect your privacy and are committed to protect it through our observance with this privacy policy."
              />
            </div>

            <div className="row mt-5">
              <p className="pTextForNormalPages">
                Effective Date: 05 October, 2023
              </p>
            </div>

            <div className="privacy-content">
              <strong>1. Our Commitment to Privacy</strong>
              <p style={{ textAlign: "left" }}>
                Welcome to Service64 ("we," "our," or "us"). Your privacy is
                important to us. Please carefully read this Privacy Policy
                before ordering any meal or using our services via our website
                or mobile application. By using our services, you agree to abide
                by our terms and conditions. This policy may change, and your
                continued use of our services following updates constitutes
                acceptance of those changes. Please review this policy
                periodically for updates.
              </p>
            </div>
            <div className="privacy-content">
              <strong>2. Third-Party Links</strong>
              <p style={{ textAlign: "left" }}>
                Our websites, in all versions and formats, may contain links to
                third-party websites not subject to this Privacy Policy. We do
                not control these websites or their privacy practices. Please
                review the privacy policies of third-party websites to
                understand their information handling practices.
              </p>
            </div>
            <div className="privacy-content">
              <strong>3. What is Personal Information?</strong>
              <p style={{ textAlign: "left" }}>
                Personal information refers to any information identifying an
                individual. Service64 is responsible for personal information
                under our control. We have implemented policies, training, and
                security procedures to ensure compliance with this Privacy
                Policy and safeguard the personal information you provide.
              </p>
            </div>
            <div className="privacy-content">
              <strong>4. Personal Information Exemptions</strong>
              <p style={{ textAlign: "left" }}>
                This Privacy Policy does not apply to:
              </p>
              <ul>
                <li>
                  Business contact information (e.g., name, title, work contact
                  information) used solely for professional communication.
                </li>
                <li>Publicly available information, such as directory-listed contact details.</li>
                <li>Aggregated and non-identifiable information, including demographics and statistics.</li>
              </ul>
            </div>
            <div className="privacy-content">
              <strong>5. Collection of Personal Information</strong>
              <ul>
                <li>
                  We may collect personal contact information (e.g., name, address, email, and phone numbers) through various means, including forms and other communication methods.
                </li>
                <li>
                  We may also collect demographic information and preferences to understand your interests, improve our products and services, and determine eligibility for additional offerings.
                </li>
              </ul>
            </div>
            <div className="privacy-content">
              <strong>6. Use of Personal Information</strong>
              <p style={{ textAlign: "left" }}>
                We use your personal information for various purposes, including:
              </p>
              <ul>
                <li>Fulfilling your requests for information, products, or services.</li>
                <li>Responding to inquiries and improving our offerings.</li>
                <li>Sending you updates, enhancements, and notices related to products or services.</li>
                <li>Providing Marketing Offers (you may opt-out at any time).</li>
              </ul>
            </div>
            <div className="privacy-content">
              <strong>7. Social Media Login</strong>
              <p style={{ textAlign: "left" }}>
                If you use social media login credentials, we may collect, use, and disclose information from your social media account for various purposes, including fulfilling requests, improving services, and providing Marketing Offers. Adjust permissions through your social media account's privacy settings.
              </p>
            </div>
            <div className="privacy-content">
              <strong>8. Customer Service Records</strong>
              <p style={{ textAlign: "left" }}>
                We maintain notes and instructions to serve you better. We may record or monitor telephone calls for training and quality assurance. We may also keep records of interactions with our customer service team.
              </p>
            </div>
            <div className="privacy-content">
              <strong>9. Payment Information</strong>
              <p style={{ textAlign: "left" }}>
                If you purchase services through our online shopping carts, we collect credit card or PayPal information solely for order fulfillment, refunds, or credits.
              </p>
            </div>
            <div className="privacy-content">
              <strong>10. Opt-Out and Complaints</strong>
              <p style={{ textAlign: "left" }}>
                If you opt-out of personal information use, we'll honor your request and may retain your details as required by law. Complaints will be collected for investigation and response purposes.
              </p>
            </div>
            <div className="privacy-content">
              <strong>11. Market Research Surveys</strong>
              <p style={{ textAlign: "left" }}>
                Occasionally, we conduct market research surveys. Your participation is voluntary, and your information will be aggregated, not identifying you individually.
              </p>
            </div>
            <div className="privacy-content">
              <strong>12. Cookies and Online Behavioral Advertising</strong>
              <p style={{ textAlign: "left" }}>
                We use cookies to understand site usage, serve ads correctly, and improve your experience. Adjust your browser preferences to manage cookies, but note that this may affect site functionality. We may also use web beacons and similar technologies to manage advertising.
              </p>
            </div>
            <div className="privacy-content">
              <strong>13. Analytics</strong>
              <p style={{ textAlign: "left" }}>
                We use IP addresses for demographic information and site administration. We may track anonymous usage patterns for targeted advertising and user behavior analysis.
              </p>
            </div>
            <div className="privacy-content">
              <strong>14. Combining Information</strong>
              <p style={{ textAlign: "left" }}>
                We may combine personal information with publicly available records or third-party data to better understand our customers and enhance our products and services.
              </p>
            </div>
            <div className="privacy-content">
              <strong>15. Disclosure of Personal Information</strong>
              <span style={{ textDecoration: "underline" }}>We may disclose your personal information:</span>
              <ul>
                <li>To partners, advertisers, and merchants for non-identifiable, aggregated data.</li>
                <li>To third-party service providers for order fulfillment, processing, and specialized services.</li>
                <li>For legal compliance and obligations.</li>
                <li>In connection with business transactions (e.g., sale or financing).</li>
              </ul>
            </div>
            <div className="privacy-content">
              <strong>16. Safeguards</strong>
              <p style={{ textAlign: "left" }}>
                We have implemented technical, contractual, administrative, and physical security measures to protect your personal information. Our email communication may not be secure; please avoid sending sensitive information by email. Review your browser settings to manage cookies.
              </p>
            </div>
            <div className="privacy-content">
              <strong>17. User-Generated Content</strong>
              <p style={{ textAlign: "left" }}>
                Content created by users on our sites is public information and may be used and disclosed in accordance with our User Agreement.
              </p>
            </div>
            <div className="privacy-content">
              <strong>18. Contact Us</strong>
              <p style={{ textAlign: "left" }}>
                If you have questions or concerns about our Privacy Policy, please get in touch with us at <a href="https://www.service64.com/contact">Service64/contact</a>.
              </p>
              <p style={{ textAlign: "left" }}>
                Please replace "Service64," "05 October, 2023," and "[Contact Information]" with the appropriate details specific to your company. Ensure that your privacy practices align with the policy outlined in your privacy policy document, and keep it up-to-date as your practices evolve.
              </p>
            </div>
          </div>
        </section>

        {/* NewsLetter */}
        <NewsLetter />

        {/* Footer */}
        <Footer />

        <ScrollTopBtn />
      </main>
    );
  }
}

export default PrivacyPolicy;
