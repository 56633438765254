import React from 'react';

const items = [
    {
        image: require("../../../assets/images/icon/truck-96.png"),
        title: 'Home Shifting',
        url: '/service-providers',
    },
    {
        image: require("../../../assets/images/icon/plug-96.png"),
        title: 'Electrician',
        url: '/service-providers',
    },
    {
        image: require("../../../assets/images/icon/gas-burner-96.png"),
        title: 'Gas Mistri',
        url: '/service-providers',
    },
    {
        image: require("../../../assets/images/icon/ambulance-96.png"),
        title: 'Ambulance',
        url: '/service-providers',
    },
    {
        image: require("../../../assets/images/icon/plumbing-96.png"),
        title: 'Plumbing',
        url: '/service-providers',
    },
    {
        image: require("../../../assets/images/icon/multiple-devices-96.png"),
        title: 'Electronic Repair',
        url: '/service-providers',
    }
];

const PopularCategories = () => {
    return (
        <>
            {items.map((item, index) => (
                <div className="col-lg-2 col-sm-2 col-6 column-td-6" key={index}>
                    <div className="category-item mb-4 mt-0 ml-0 mr-0 p-0">
                        <div className="cat-main">
                            <div className="cat-item-home">
                                <span className="cat-icon">
                                    <img src={item.image} loading="lazy" alt={item.title} />
                                </span>
                                <span className="category-title">{item.title}</span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default PopularCategories;
