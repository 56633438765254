import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { edit_photo } from "../../store/action";
import { storage } from "../../components/addlisting/Storage";
import imageCompression from "browser-image-compression";
import userImg from '../../assets/images/team2.jpg';
import Cropper from '../../components/addlisting/Cropper';

const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
};

const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 0,
    marginRight: 0,
    width: "100%",
    height: "auto",
    padding: 4,
    boxSizing: "border-box",
};

const thumbInner = {
    width: "100%",
};

const imgStyle = {
    display: "block",
    width: "100%",
    height: "auto",
};

function PhotoUploader(props) {
    const current_user = JSON.parse(localStorage.getItem('__current_user__'));
    const [files, setFiles] = useState([]);
    const [progress, setProgress] = useState(0);
    const [imgUpload, setImgUpload] = useState(false);
    const [photo, setPhoto] = useState(userImg);
    const [photoUploaded, setPhotoUploaded] = useState(false);
    const [getCropedImage, setGetCropedImage] = useState(null);
    const [photoTmp, setPhotoTmp] = useState(null);

    useEffect(() => {
        if (current_user && current_user.user_type === "Seller") {
            setPhoto(current_user.seller_img);
        }
    }, [current_user]);

    const deleteOldPic = () => {
        if (current_user && current_user.user_type === "Seller") {
            const desertRef = storage.ref().child(`/images/${current_user.photo_name}`);
            desertRef.delete().catch(console.log);
        }
    };

    const handleFileChange = (event) => {
        const file = event[0];
        const reader = new FileReader();
        reader.onload = () => setPhotoTmp(reader.result);
        reader.readAsDataURL(file);
    };

    const handleValidatePhoto = () => {
        if (getCropedImage) {
            getCropedImage().then((img) => {
                img.lastModifiedDate = new Date();
                img.name = `${new Date().getTime()}.jpg`;
                savePhoto([img]);
                setFiles([{ ...img, preview: URL.createObjectURL(img) }]);
            }).catch(console.log);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        onDrop: handleFileChange,
    });

    const savePhoto = async (acceptedFiles) => {
        const options = {
            maxSizeMB: 4,
            maxWidthOrHeight: 220,
            useWebWorker: true,
            fileType: "image/jpeg",
        };
        deleteOldPic();
        const d = new Date().getTime().toString();

        try {
            const compressedFile = await imageCompression(acceptedFiles[0], options);
            const uploadTask = storage.ref(`/images/${d + compressedFile.name}`).put(compressedFile);

            uploadTask.on("state_changed", (snapShot) => {
                setProgress(Math.round((snapShot.bytesTransferred / snapShot.totalBytes) * 100));
            }, console.log, async () => {
                const fileName = d + compressedFile.name;
                const fireBaseUrl = await storage.ref("images").child(fileName).getDownloadURL();
                setPhoto(fireBaseUrl);
                setImgUpload(false);
                setPhotoUploaded(false);

                const body = {
                    seller_img: fireBaseUrl,
                    photo_name: fileName,
                    _id: current_user._id
                };
                props.actions.edit_photo(body).then(console.log);
            });
        } catch (error) {
            console.log(error.message);
        }
    };

    const thumbs = files.map((file) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={imgStyle} alt="Author Profile" />
            </div>
        </div>
    ));

    return (
        <div className="user-pro-img mb-4">
            <div className="dropdown">
                {!imgUpload ? (
                    <>
                        <img src={photo} alt="user" />
                        <button onClick={() => setImgUpload(true)}
                            className="theme-btn edit-btn dropdown-toggle border-0 after-none">
                            <i className="la la-photo"></i> Edit
                        </button>
                    </>
                ) : (
                    <div className="upload-btn-box">
                        <div className="drag-and-drop-wrap text-center">
                            <div className="drag-and-drop-file">
                                {photoUploaded ? (
                                    <div>
                                        <aside style={thumbsContainer}>{thumbs}</aside>
                                        {!imgUpload && (
                                            <>
                                                <Cropper image={photoTmp} setGetCropedImage={setGetCropedImage} />
                                                <div className="btn-box text-left">
                                                    <button className="theme-btn border-0 button-success mr-1" onClick={handleValidatePhoto}>Save changes</button>
                                                    <button className="theme-btn border-0" onClick={() => { setPhotoUploaded(false); setImgUpload(false); }}>Cancel</button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <div {...getRootProps({ className: "dropzone" })}>
                                            <input {...getInputProps()} />
                                            <span className="drag-drop-icon"></span>
                                            <h3>Drag & Drop Files Here to Upload</h3>
                                            <Link to="#" className="drag-drop-btn">Browse Files</Link>
                                        </div>
                                        <button className="theme-btn border-0" onClick={() => { setPhotoUploaded(false); setImgUpload(false); }}>Cancel</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ edit_photo }, dispatch),
});

export default connect(null, mapDispatchToProps)(PhotoUploader);
