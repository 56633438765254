import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import HeaderTwo from "../../components/common/HeaderTwo";
import BannerOne from "../../components/banner/banner1/BannerOne";
import SectionsHeading from "../../components/common/SectionsHeading";
import PopularCategories from "../../components/other/categories/PopularCategories";
import Button from "../../components/common/Button";
import CtaOne from "../../components/other/cta/CtaOne";
import NewsLetter from "../../components/other/cta/NewsLetter";
import Footer from "../../components/common/footer/Footer";
import MetaDecorator from "../../utils/metaDecorator";
import metaData from "../../meta/home";
import { get_loc_has_apr_users } from "../../store/action";

class Home extends Component {
  state = {
    Dhaka: 0,
    Chattogram: 0,
    Mymensingh: 0,
    Khulna: 0,
    Barisal: 0,
    Gazipur: 0,
    Sylhet:0,
    Rajshahi:0
  };

  componentDidMount() {
    this.getLocations();
  }

  getLocations() {
    this.props.actions.get_loc_has_apr_users().then((res) => {
      if (res.data.length > 0) {
        const cities = {};
        res.data.forEach((city) => {
          const noOfSellers = city.locations.reduce(
            (total, current) => ({
              noOfApproveUser: total.noOfApproveUser + current.noOfApproveUser,
            }),
            { noOfApproveUser: 0 }
          );
          cities[city.label] = noOfSellers.noOfApproveUser;
        });
        this.setState(cities);
      }
    });
  }

  render() {
    return (
      <main className="home-1">
        {/* SEO-Meta-Data */}
        {metaData.show && (
          <MetaDecorator
            title={metaData.pageTitle}
            description={metaData.pageDescription}
          />
        )}
        <HeaderTwo history={this.props.history} />
        <BannerOne history={this.props.history} />

        <section className="cat-area padding-top-80px padding-bottom-80px">
          <div className="container">
            <div className="padding-bottom-50px category-p-text">
              <h2 className="h_title">Most Popular Categories</h2>
              <p className="h_raw_text">
                You can work on these categories by registering on service64
                with few simple steps to become discoverable to your customers
                easily.
              </p>
            </div>

            <div className="row">
              <PopularCategories />
            </div>
          </div>
        </section>

        <section className="cta-area column-sm-center padding-bottom-80px">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-9 text-left">
                <SectionsHeading
                  title="We can help you get a job that you love doing"
                  titleClass="mb-3 font-size-28"
                  descClass="font-size-17"
                  desc="Don't have any shop or contact address to get your customers? We are here to help you to get calls from the people who are looking for your service."
                />
              </div>

              <div className="col-lg-3">
                <div className="btn-box">
                  <Button text="Create Account" url="/sign-up" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cat-area">
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-3 col-6 col-md-4 col-sm-6 mb-3">
                <div className="division-area dhaka ">
                  <h4>Dhaka</h4>
                  <p>{this.state.Dhaka} Profile</p>
                </div>
              </div>
              <div className="col-lg-3 col-6 col-md-4 col-sm-6 mb-3">
                <div className="chattogram division-area">
                  <h3>Chattogram</h3>
                  <p>{this.state.Chattogram} Profile</p>
                </div>
              </div>
              <div className="col-lg-3 col-6 col-md-4 col-sm-6 mb-3">
                <div className="mymensingh division-area">
                  <h3>Mymensingh</h3>
                  <p>{this.state.Mymensingh} Profile</p>
                </div>
              </div>
              <div className="col-lg-3 col-6 col-md-4 col-sm-6 mb-3">
                <div className="khulna division-area">
                  <h3>Khulna</h3>
                  <p>{this.state.Khulna} Profile</p>
                </div>
              </div>
              <div className="col-lg-3 col-6 col-md-4 col-sm-6 mb-3">
                <div className="barishal division-area">
                  <h3>Barishal</h3>
                  <p>{this.state.Barisal} Profile</p>
                </div>
              </div>
              <div className="col-lg-3 col-6 col-md-4 col-sm-6 mb-3">
                <div className="gazipur division-area">
                  <h3>Gazipur</h3>
                  <p>{this.state.Gazipur} Profile</p>
                </div>
              </div>

              <div className="col-lg-3 col-6 col-md-4 col-sm-6 mb-3">
                <div className="rajshahi division-area">
                  <h3>Rajshahi</h3>
                  <p>{this.state.Rajshahi} Profile</p>
                </div>
              </div>

              <div className="col-lg-3 col-6 col-md-4 col-sm-6 mb-3">
                <div className="sylhet division-area">
                  <h3>Sylhet</h3>
                  <p>{this.state.Sylhet} Profile</p>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="cta-area cta-area3 padding-top-100px padding-bottom-80px">
          <CtaOne />
        </section>

        <NewsLetter />
        <Footer />
      </main>
    );
  }
}

const mapDispatchToProps = (dispatchEvent) => ({
  actions: bindActionCreators({ get_loc_has_apr_users }, dispatchEvent),
});

export default connect(null, mapDispatchToProps)(Home);
