import React from 'react';

const items = [
    {
        image: require("../../../assets/images/icon/worker-96.png"),
        title: 'Constructor',
        url: '/list',
    },
    {
        image: require("../../../assets/images/icon/paint-bucket-96.png"),
        title: 'Painter',
        url: '/list',
    },
    {
        image: require("../../../assets/images/icon/weber-96.png"),
        title: 'Cook',
        url: '/list',
    },
    {
        image: require("../../../assets/images/icon/christmas-gift-96.png"),
        title: 'Decorator',
        url: '/list',
    },
    {
        image: require("../../../assets/images/icon/police-96.png"),
        title: 'Caretaker',
        url: '/list',
    },
    {
        image: require("../../../assets/images/icon/car-rental-96.png"),
        title: 'Rent a car',
        url: '/list',
    },
    {
        image: require("../../../assets/images/icon/microwave-96.png"),
        title: 'Helping Hand',
        url: '/list',
    },
    {
        image: require("../../../assets/images/icon/saw-96.png"),
        title: 'Carpenter',
        url: '/list',
    },
    {
        image: require("../../../assets/images/icon/pump-96.png"),
        title: 'Pump Mechanic',
        url: '/list',
    },
    {
        image: require("../../../assets/images/icon/storage-tank-96.png"),
        title: 'Water Tank Cleaner',
        url: '/list',
    },
    {
        image: require("../../../assets/images/icon/thumbnails-96.png"),
        title: 'Tiles Mistri',
        url: '/list',
    },
    {
        image: require("../../../assets/images/icon/closed-window-96.png"),
        title: 'Thai Glass Mistri',
        url: '/list',
    }
];

const PopularCategoriesMore = () => {
    return (
        <>
            {items.map((item, index) => (
                <div className="col-lg-2 column-td-6 col-6" key={index}>
                    <div className="category-item mb-4 mt-0 ml-0 mr-0 p-0">
                        <div className="cat-main">
                            <div className="cat-item-home">
                                <span className="cat-icon">
                                    <img src={item.image} alt={item.title} />
                                </span>
                                <span className="category-title">{item.title}</span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default PopularCategoriesMore;
