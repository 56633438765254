import React, {Component} from 'react';
import SectionsHeading from "../common/SectionsHeading";

class About2 extends Component {
    state = {
        images: [
            {
                img: require('../../assets/images/curology-6CJg-fOTYs4-unsplash.jpg'),
                boxClass: 'mt-4'
            },
            {
                img: require('../../assets/images/neonbrand-60krlMMeWxU-unsplash.jpg'),
                boxClass: ''
            },
            {
                img: require('../../assets/images/steve-johnson-e5LdlAMpkEw-unsplash.jpg'),
                boxClass: 'mt-4'
            },
            {
                img: require('../../assets/images/intrapixel-7XaBaKwrV1E-unsplash.jpg'),
                boxClass: ''
            }
        ]
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="about-content">
                            <SectionsHeading
                                title="We are a web based directory for local service providers."
                                
                                descClass=" font-size-17 pr-3 mb-3"
                            >
                                <p className="sec__desc about-text font-size-17 pr-3">
                                    To that end, our goal is simple, yet audacious, to empower thousands of service providers across our loving country Bangladesh so that they provide 
                                    the best possible service - and at the same time, grow their businesses and livelihoods like never before.
                                </p>
                                <p style={{marginTop:20,}} className="sec__desc about-text font-size-17 pr-3">
                                    Guided by our core values, we’ll meet each new challenge creatively to create the best possible experience for our users, to one day become their 
                                    first and ultimate destination for any service need.
                                </p>

                                <p style={{marginTop:20,}} className="sec__desc about-text font-size-17 pr-3">
                                    We try to get the best service experience with our hand-vetted expert service providers. By providing your profiles to our app users, providing 
                                    tech-driven solutions for scheduling. Empowering you to perform at your best lies at the heart of our business, and we take that very seriously.
                                </p>
                            </SectionsHeading>
                        </div>
                    </div>
                    <div style={{
                        alignSelf:"center",
                    }} className="col-lg-6">
                        <div className="img-boxes">
                            <div className="row">
                                <img src={require('../../assets/images/about_photo.jpg')} alt="about" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default About2;