import React, { Component } from "react";
import SignUpBox from "../components/other/account/SignUpBox";
import GeneralHeader from "../components/common/GeneralHeader";
import Breadcrumb from "../components/common/Breadcrumb";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import { Tabs} from "react-tabs";
import MetaDecorator from "../utils/metaDecorator";
import metaData from "../meta/signUp";
class SignUp extends Component {
  state = {
    breadcrumbimg: require("../assets/images/s4.jpg"),
  };
  render() {
    return (
      <main className="signup-page">
        {/* SEO-Meta-Data */}
        {metaData.show && (
          <MetaDecorator
            title={metaData.pageTitle}
            description={metaData.pageDescription}
          />
        )}

        {/* Header */}
        <GeneralHeader />

        {/* Breadcrumb */}
        <Breadcrumb CurrentPgTitle="Signup to reveiw" img={this.state.breadcrumbimg} /> 

        <section className="form-shared padding-top-40px">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 mx-auto signupHeading">
                <h2>Register as a reviewer</h2>
                <p> Your review is very important to us, by regesterign with this form you will be able to review any seller for his work{" "}
                </p>
              </div>
            </div>
          </div>
        </section>     

          
          <section className="form-shared padding-top-40px padding-bottom-100px">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-12 mx-auto">
                          <Tabs>
                              <div className="col-lg-12">  
                                <div className='col-lg-6 mx-auto'>
                                    <SignUpBox title="Create an account!" history={this.props.history} subtitle="with your social network" />
                                </div>
                              </div>
                          </Tabs>
                      </div>
                  </div>
              </div>
          </section>

          {/*

        <section className="form-shared padding-top-40px">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 mx-auto signupHeading">
                <h2>এখানে রেজিস্ট্রেশন করুন</h2>
                <p> এখানে সাইন আপ এর মাধ্যমে আপনি আমাদের অ্যাপ এর একজন কর্মী হতে পারবেন, 
আর আপনার সিলেক্ট করা এলাকার লোকজন আপনার সাথে সহজেই যোগাযোগ করতে পারবে। {" "}
                </p>
              </div>
            </div>
          </div>
        </section>

        <Seller_reg_form history={this.props.history} />


          */}
        {/* Newsletter 
                <NewsLetter />

                {/* Footer */}
        <Footer />

        <ScrollTopBtn />
      </main>
    );
  }
}

export default SignUp;
