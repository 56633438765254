import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';

const socials = [
    {
        icon: <FaFacebookF />,
        url: 'https://www.facebook.com/service64bd/',
        name: 'Facebook'
    },
    {
        icon: <FaTwitter />,
        url: 'https://twitter.com/juwelpc1',
        name: 'Twitter'
    },
    {
        icon: <FaLinkedinIn />,
        url: 'https://www.linkedin.com/in/md-juwel-38a269ba/',
        name: 'LinkedIn'
    }
];

const SocialProfile = () => {
    return (
        <ul className="social-profile padding-top-30px padding-bottom-30px">
            {socials.map((item, i) => (
                <li key={i}>
                    <a href={item.url} aria-label={item.name} target="_blank" rel="noopener noreferrer">
                        {item.icon}
                        <span className="visually-hidden">{item.name}</span>
                    </a>
                </li>
            ))}
        </ul>
    );
};

export default SocialProfile;
